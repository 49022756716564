import { Row, Col, Popover, Modal } from "react-bootstrap";
import { useWhiteLabelLan } from "@hooks/useLayout";
import {
  Destination,
  Div,
  MainDiv,
  OverlayCustom,
  PopoverCustom,
  RowDiv,
  Span,
  Title,
  Type,
  MainDivSuggestedNotFound,
  DivTittleSuggestedNotFound,
  DivImgSuggestedNotFOund,
  CustomPopoverHeader,
  DivCookie,
  SpanDateCookie,
  SpanPersonsCookie,
  RowDivCookie,
  ModalClose,
  //ModalHeaderContainer,
  ModalBody,
  InputSearchModal,
  RowModalSeach,
  LoaderDiv,
} from "./styles";
import { autoCompleteIcons } from "@data/autocompleate";
import { useGlobalContext } from "@hooks/useGlobalContext";
import moment from "moment";
import { getCookie } from "cookies-next";

type LocationsOverlayPropsType = {
  innerRef: any;
  target: any;
  show: boolean;
  suggests: HotelAutoSuggestDataType[];
  setShow: any;
  handleSetPersonTotal: (configuration: any, type: string) => void;
  isMobile: boolean;
  handleDestinationsOverlay: any;
  setRefresh: any;
  refresh: boolean;
  loading?: boolean;
  actualLocation: string;
  setSearchText: (val: string) => void;
  setSuggestedListcss: any;
};

export const LocationsOverlay = ({
  innerRef,
  show,
  target,
  suggests = [],
  setShow,
  handleSetPersonTotal,
  isMobile,
  handleDestinationsOverlay,
  setRefresh,
  refresh,
  loading,
  actualLocation,
  setSearchText,
  setSuggestedListcss,
}: LocationsOverlayPropsType) => {
  const { getLangLabel } = useWhiteLabelLan();
  const { search, setSearch } = useGlobalContext();

  const getTitle = (name: string) => {
    return name.split(",")[0];
  };
  const getDestination = (name: string) => {
    return name.substring(name.indexOf(",") + 1, name.length);
  };
  const getType = (val: any) => {
    let langCode = String(getCookie("lan_code"));
    langCode = langCode.length >= 2 ? langCode.substring(0, 2) : "";
    console.log("getType >>>>", langCode);

    try {
      if (langCode && val[langCode]) {
        return val[langCode];
      }
    } catch (error: any) {
      console.log(error);
      return val?.label?.en;
    }
    return val?.label?.en;
  };

  const SuggestedLoop = ({
    suggests,
  }: {
    suggests: HotelAutoSuggestDataType[];
  }) => {
    return (
      <>
        {suggests?.map((suggest, index: number) => {
          const searchType = autoCompleteIcons.find((f) => {
            return String(f.type) === String(suggest.search_type);
          });
          return (
            <Div
              className="destination-text"
              key={`suggest-${index}`}
              theme={{
                url: searchType?.url,
              }}
              fluid
              onClick={() => {
                setSearch({ ...search, ...suggest });
                setSearchText(`${suggest.search_text}`);
                setShow(false);
              }}
            >
              <RowDiv>
                <Col xs={7}>
                  <Title
                    className={`!tw-text-inspira-brandcolor-txt titlelabel-search ${
                      isMobile ? "" : "titlelabel-searchmobile"
                    }`}
                  >
                    {getTitle(suggest.search_text)}
                  </Title>
                  <Destination
                    className={`!tw-text-inspira-accentcolor-txt destinationlabel-search ${
                      isMobile ? "" : "destinationlabel-searchmobile"
                    }`}
                  >
                    {getDestination(suggest.search_text)}
                  </Destination>
                </Col>
                <Type xs={5}>
                  <Span className="!tw-text-inspira-accentcolor-txt typelabel-search">
                    {getType(searchType?.label)}
                  </Span>
                </Type>
              </RowDiv>
            </Div>
          );
        })}
      </>
    );
  };

  const SuggestedNotFoundElements = () => {
    return (
      <MainDivSuggestedNotFound fluid>
        <DivTittleSuggestedNotFound>
          {getLangLabel("NO RESULT FOUND !")}
        </DivTittleSuggestedNotFound>
        <DivImgSuggestedNotFOund></DivImgSuggestedNotFOund>
      </MainDivSuggestedNotFound>
    );
  };
  const LoadersSpin = () => {
    return (
      <LoaderDiv fluid>
        <Row>
          <Col xs={12}>
            <div className="spinner-border text-secondary" role="status">
              <span className="sr-only"></span>
            </div>
          </Col>
        </Row>
      </LoaderDiv>
    );
  };
  const GetCookieElements = () => {
    if (localStorage.getItem("destinationHistory")) {
      var json = JSON.parse(
        String(localStorage.getItem("destinationHistory") || "[{}]")
      );
      setSuggestedListcss(true);
      console.log("destinationHistory", json);
      return json?.map((suggest: any, index: number) => {
        const searchType = autoCompleteIcons.find((f) => {
          return String(f.type) === String(suggest.search_type);
        });

        var inDate = "";
        var outDate = "";
        var roomNum = 0;
        var adultNum = 0;
        var childNum = 0;
        if (
          suggest.check_in_date &&
          suggest.check_in_date.split("-").length == 3
        ) {
          inDate =
            suggest.check_in_date.split("-")[2] +
            "/" +
            suggest.check_in_date.split("-")[1] +
            "/" +
            suggest.check_in_date.split("-")[0];
        }
        if (
          suggest.check_out_date &&
          suggest.check_out_date.split("-").length == 3
        ) {
          outDate =
            suggest.check_out_date.split("-")[2] +
            "/" +
            suggest.check_out_date.split("-")[1] +
            "/" +
            suggest.check_out_date.split("-")[0];
        }

        if (
          Array.isArray(suggest?.paxConfig) &&
          suggest?.paxConfig.length > 0
        ) {
          suggest?.paxConfig?.map((obj: any) => {
            roomNum = Number(obj?.room);
            adultNum += Number(obj?.adult);
            childNum += Number(obj?.children);
          });
        } else {
          roomNum = 1;
          adultNum = 1;
        }

        /* START FORMAT CHECKIN CHECKOUT */
        let tmpCheckIn = moment(suggest.dates.checkIn, "YYYY-MM-DD").toDate();
        let tmpCheckOut = moment(suggest.dates.checkOut, "YYYY-MM-DD").toDate();
        suggest.dates.checkIn = tmpCheckIn;
        suggest.dates.checkOut = tmpCheckOut;
        /* END FORMAT CHECKIN CHECKOUT */

        // esto sirve para no mostrar fechas pasadas
        const current = moment();
        const date1 = moment(inDate, "DD/MM/YYYY");
        const diff = date1.diff(current, "days");

        if (diff < 0) {
          return null;
        }
        console.log({ suggest }, { search });
        return (
          <DivCookie
            className="DivCookie"
            key={`cookieHistory-${index}`}
            theme={{
              url: searchType?.url,
            }}
            fluid
            onClick={() => {
              setSearch({
                ...search,
                ...suggest,
                search_text: suggest.destination_name,
              });
              setSearchText(suggest.destination_name);
              setShow(false);
              handleSetPersonTotal(suggest.paxConfig, "all");
            }}
          >
            <RowDivCookie>
              <Col>
                <Title
                  className={`sugested-title ${
                    isMobile ? "" : "sugested-titlemobile"
                  }`}
                >
                  {suggest.destination_name}
                </Title>
                <SpanDateCookie className={` ${isMobile ? "" : "datecookie"}`}>
                  {inDate + " - " + outDate}
                </SpanDateCookie>
                <SpanPersonsCookie
                  className={` ${isMobile ? "" : "personcookie"}`}
                >
                  {roomNum +
                    " Room, " +
                    adultNum +
                    " Adults, " +
                    childNum +
                    " Child"}
                </SpanPersonsCookie>
              </Col>
            </RowDivCookie>
          </DivCookie>
        );
      });
    } else {
      return <></>;
    }
  };

  const Suggest = () => {
    return (
      <MainDiv
        fluid={true}
        theme={{ isMobile }}
        className={"my-trip-list-scroll"}
      >
        {suggests.length > 0 && <SuggestedLoop suggests={suggests} />}
        {loading && suggests.length == 0 && <LoadersSpin />}
        {suggests.length == 0 && actualLocation != "" && !loading && (
          <SuggestedNotFoundElements />
        )}
        {suggests.length == 0 && actualLocation == "" && <GetCookieElements />}
      </MainDiv>
    );
  };

  return (
    <>
      {!isMobile ? (
        <OverlayCustom
          show={show}
          target={target}
          placement="bottom-start"
          container={innerRef}
          containerPadding={20}
          onHide={() => setShow(false)}
          rootClose={true}
        >
          <PopoverCustom
            id="popover-contained"
            theme={{
              isCookie:
                suggests.length == 0 && target?.value == "" ? true : false,
            }}
          >
            {suggests.length == 0 && target?.value == "" && (
              <CustomPopoverHeader
                className="CustomPopoverHeader"
                theme={{ isMobile }}
              >
                {getLangLabel("YOUR RECENT SEARCHES")}
              </CustomPopoverHeader>
            )}
            <Popover.Body>
              <Suggest />
            </Popover.Body>
          </PopoverCustom>
        </OverlayCustom>
      ) : (
        <Modal show={show} fullscreen={true}>
          <RowModalSeach>
            <Col xs={11} md={11}>
              <InputSearchModal
                id={"input-destination-mobil"}
                type={"text"}
                placeholder={
                  isMobile
                    ? `${getLangLabel("ENTER DESTINATION OR PROPERTY")}`
                    : `${getLangLabel("DESTINATION")}`
                }
                value={search.search_text || ""}
                onChange={(event) => {
                  handleDestinationsOverlay(event);
                }}
                onClick={(event) => {
                  if (localStorage.getItem("destinationHistory")) {
                    handleDestinationsOverlay(event);
                    setShow(true);
                    setRefresh(!refresh);
                  }
                  setSearch({ ...search, search_text: "" });
                }}
                className={`${isMobile ? "" : "inputsearchplaceholder"}`}
              />
            </Col>
            <Col xs={1} md={1}>
              <ModalClose
                src="/images/general/mainClose.svg"
                onClick={() => setShow(false)}
              ></ModalClose>
            </Col>
          </RowModalSeach>
          {suggests.length == 0 && target?.value == "" && (
            <Row>
              <Col xs={12}>
                <CustomPopoverHeader
                  className="CustomPopoverHeader"
                  theme={{ isMobile }}
                >
                  {getLangLabel("YOUR RECENT SEARCHES")}
                </CustomPopoverHeader>
              </Col>
            </Row>
          )}
          <ModalBody>
            <Suggest />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};
