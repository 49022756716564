import {
  MainContainer,
  FooterCol,
  FooterRow,
  TitleCol,
  TitleRow,
  CloseCol,
  BodyRow,
  BodyCol,
  CustomLabel,
  CustomIcon,
  CustomPlusIcon,
  //  CustomAddPlusIcon,
  CustomMinusIcon,
  BodyChildrenRow,
  BodyChildrenCol,
  UL,
  LI,
  CustomSpan,
  CustomSelect,
  CustomSelectOption,
  SpanClose,
  ButtonImage,
  LabelCertificate,
  FooterCol2,
  CloseCol2,
  TitleColRooms,
} from "../styles";
import { useGlobalContext } from "@hooks/useGlobalContext";
import { useWhiteLabelLan } from "@hooks/useLayout";
import { usePersonalWallet } from "@store/WalletPersonalStore";
import { ENABLED_PERSONAL_WALLET } from "@helpers/config";
import { getBestCertificateToCompare } from "@lib/paymentCardByOrg/logicProgram";
import { usePaymentCardByOrg } from "store/paymentCardByOrg";
import { getCookie } from "cookies-next";
import { MAX_CHILDS, MAX_PAX } from "@data/consts";
import { AddRoomButton } from "./AddRoom";
import { useEffect, useState } from "react";

type GuestConfigurationBodyPropsType = {
  isMobile: boolean;
  setShow: any;
  setPersonTotal: (config: any, type: string) => void;
  removeGuestConfiguration: (id: number) => void;
  updateGuestValue: (
    id: number,
    type: "adult" | "children" | "room",
    process: string
  ) => void;
};
export const GuestConfigurationBody = ({
  isMobile,
  setShow,
  removeGuestConfiguration,
  updateGuestValue,
  setPersonTotal,
}: GuestConfigurationBodyPropsType) => {
  const { search, setSearch } = useGlobalContext();
  const maxPeople = MAX_PAX;
  const maxChilds = MAX_CHILDS;
  //const newGuestOption = { room: 1, adult: 2, children: 0, child: [] };
  const { getLangLabel } = useWhiteLabelLan();
  const updateChildArray = (room: number, index: number, value: string) => {
    const updatedData = search.paxConfig;
    updatedData[room].child[index].age = value;
    setSearch({ ...search, paxConfig: updatedData });
  };
  const [totalChildren, setTotalChildren] = useState(0);
  const {
    walletCertificateAvailable,
    currentOptionSelected,
    walletPersonalSelected,
  } = usePersonalWallet();
  const { paymentCardByOrd } = usePaymentCardByOrg();
  /* CERTIFICATE PREVIEW */
  const certificatePreview: Get_Payment_Card_By_Org_API_Response_Type =
    getBestCertificateToCompare(
      !paymentCardByOrd ? [] : paymentCardByOrd,
      "HLT",
      String(getCookie("currency_code") || "USD")
    );
  const showCertificatePreview: boolean =
    certificatePreview?.isInsert == "SET" &&
    walletCertificateAvailable == true &&
    !walletPersonalSelected;

  const showAddMoreRoom = () => {
    let show: boolean = true;
    const WALLET_CERTIFICATE_ON: boolean =
      currentOptionSelected == "certificates" && walletPersonalSelected
        ? true
        : false;
    if (
      ENABLED_PERSONAL_WALLET &&
      (walletCertificateAvailable ||
        showCertificatePreview ||
        WALLET_CERTIFICATE_ON)
    ) {
      show = false;
    }
    return show;
  };
  const show_BOOK_One_By_One = () => {
    let show: boolean = false;
    const WALLET_CERTIFICATE_ON: boolean =
      currentOptionSelected == "certificates" && walletPersonalSelected
        ? true
        : false;
    if (
      ENABLED_PERSONAL_WALLET &&
      (walletCertificateAvailable ||
        WALLET_CERTIFICATE_ON ||
        showCertificatePreview)
    ) {
      show = true;
    }
    return show;
  };
  useEffect(() => {
    let counterChildrens = 0;
    for (let room of search.paxConfig) {
      counterChildrens += room.children;
    }
    setTotalChildren(counterChildrens);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.paxConfig]);
  return (
    <MainContainer>
      <TitleRow className="guest-config-row-title">
        <TitleCol lg={4} xs={4}>
          <TitleColRooms className="roomslabel-search">
            {getLangLabel("ROOMS")}
          </TitleColRooms>
        </TitleCol>
        <TitleCol
          lg={4}
          xs={4}
          className="guest-config-title-center adultslabel-search"
        >
          {" "}
          {getLangLabel("ADULTS")}
        </TitleCol>
        <TitleCol
          xs={4}
          className="guest-config-title-end childrenlabel-search"
        >
          {" "}
          {totalChildren < 2 ? getLangLabel("CHILD") : getLangLabel("CHILDREN")}
        </TitleCol>
      </TitleRow>
      {search.paxConfig?.map((data, index) => {
        return (
          <div key={"main-row-wrapper" + index}>
            <BodyRow key={"row-" + index} className="guest-config-bodyrow">
              <BodyCol lg={4} xs={4} key={"col-room-" + index}>
                <CustomIcon
                  key={"delete-" + index}
                  onClick={() => removeGuestConfiguration(index)}
                  className="guet-config-custom-icon deletebtn-search"
                />{" "}
                <CustomLabel
                  className="!tw-text-inspira-brandcolor-txt guest-config-custom-label labelbtm-search"
                  theme={{ color: "#6b71a6" }}
                >
                  {getLangLabel("ROOM")} {index + 1}
                </CustomLabel>{" "}
              </BodyCol>
              <BodyCol key={"col-adult-" + index} lg={4} xs={4}>
                {" "}
                <CustomMinusIcon
                  key={"minus-adult-" + index}
                  theme={{
                    icon: "minus.svg",
                    flagEnable: data.adult == 1 ? false : true,
                  }}
                  onClick={() => updateGuestValue(index, "adult", "minus")}
                >
                  <ButtonImage
                    className="minus-search"
                    src="/images/general/minus.svg"
                    alt=""
                  />
                </CustomMinusIcon>
                <CustomLabel
                  className="!tw-text-inspira-brandcolor-txt guest-config-custom-label pxlabel-search"
                  theme={{ color: "#6b71a6" }}
                >
                  {data.adult}
                </CustomLabel>
                <CustomPlusIcon
                  key={"plus-adult-" + index}
                  theme={{
                    icon: "plus.svg",
                    flagEnable:
                      data.adult + data.children > MAX_PAX ? true : false,
                  }}
                  onClick={() =>
                    data.adult + data.children < maxPeople
                      ? updateGuestValue(index, "adult", "plus")
                      : null
                  }
                  className="plus-search"
                />{" "}
              </BodyCol>
              <BodyCol key={"col-children-" + index} xs={4}>
                <CustomMinusIcon
                  key={"minus-children-" + index}
                  theme={{
                    icon: "minus.svg",
                    flagEnable: data.children == 0 ? false : true,
                  }}
                  onClick={() => updateGuestValue(index, "children", "minus")}
                >
                  <ButtonImage
                    src="/images/general/minus.svg"
                    alt=""
                    className="minus-search"
                  />
                </CustomMinusIcon>
                <CustomLabel
                  className="!tw-text-inspira-brandcolor-txt guest-config-custom-label pxlabel-search"
                  theme={{ color: "#6b71a6" }}
                >
                  {data.children}
                </CustomLabel>
                <CustomPlusIcon
                  key={"plus-children-" + index}
                  theme={{
                    icon: "plus.svg",
                    flagEnable:
                      data.adult + data.children > maxPeople ? true : false,
                  }}
                  onClick={() => {
                    data.adult + data.children < maxPeople &&
                    data.children < maxChilds
                      ? updateGuestValue(index, "children", "plus")
                      : null;
                  }}
                  className="plus-search"
                />
              </BodyCol>
            </BodyRow>
            {data.children > 0 ? (
              <BodyChildrenRow
                classNAme="search-detail-childrenrow"
                key={"children-row-" + index}
                theme={{ color: "#f5f5fa" }}
                className="guest-config-body-childrenrow"
              >
                {Array.isArray(data?.child) &&
                  data?.child?.map(
                    (
                      childObj: GuestConfigurationChildType,
                      positionChild: number
                    ) => {
                      return (
                        <BodyChildrenCol
                          className="search-detail-childrencol"
                          key={
                            "ul-children-bodyCol" + index + "-" + positionChild
                          }
                        >
                          <UL key={"ul-children-title" + index}>
                            <LI key={"li-children-title" + index}>
                              <CustomSpan>
                                {`${getLangLabel("CHILD")} ${
                                  positionChild + 1
                                } ${getLangLabel("AGE")}`}
                              </CustomSpan>
                            </LI>
                            <LI key={"li-children-select" + index}>
                              <CustomSelect
                                className="hotels-combo-number"
                                key={
                                  "li-children-select-option-" + positionChild
                                }
                                aria-label="custom-select"
                                onChange={(e) => {
                                  updateChildArray(
                                    index,
                                    positionChild,
                                    e.target.value
                                  );
                                }}
                                value={childObj?.age || 1}
                              >
                                {Array.from(Array(12), (_e, i) => {
                                  return (
                                    <CustomSelectOption
                                      key={"option-" + i}
                                      value={i + 1}
                                      className="guest-config-custom-selectopt"
                                    >
                                      {i + 1}
                                    </CustomSelectOption>
                                  );
                                })}
                              </CustomSelect>
                            </LI>
                          </UL>
                        </BodyChildrenCol>
                      );
                    }
                  )}
              </BodyChildrenRow>
            ) : null}
            {data.adult + data.children > MAX_PAX ? (
              <BodyChildrenRow
                key={"adult-children-row-exced-" + index}
                theme={{ color: "#6b71a6" }}
              >
                <CustomLabel
                  className="guest-config-custom-label"
                  theme={{ color: "#fff" }}
                >
                  {`${getLangLabel(
                    `Sorry, only ${MAX_PAX} pax per room is possible`
                  )}`}
                </CustomLabel>
              </BodyChildrenRow>
            ) : null}
            {
              //index starts at 0 so the rooms go from 0 to 7 and the condition runs until 6
              index > 6 ? (
                <BodyChildrenRow
                  key={"rooms-row-exced-" + index}
                  theme={{ color: "#6b71a6" }}
                  className={"sorrymaxroom"}
                >
                  <CustomLabel
                    className="guest-config-custom-label"
                    theme={{ color: "#fff" }}
                  >
                    {`${getLangLabel(
                      "Sorry You can only reserve a maximum of 8 rooms"
                    )}`}
                  </CustomLabel>
                </BodyChildrenRow>
              ) : null
            }
          </div>
        );
      })}

      <FooterRow
        theme={{
          show: showAddMoreRoom(),
        }}
      >
        <FooterCol>
          <AddRoomButton isMobile={isMobile} setPersonTotal={setPersonTotal} />
        </FooterCol>
        <CloseCol theme={{ show: !isMobile }}>
          <SpanClose
            onClick={() => setShow(false)}
            className="guest-config-span-close--end"
          >
            {" "}
            {getLangLabel("CLOSE")}{" "}
          </SpanClose>
        </CloseCol>
      </FooterRow>
      <FooterRow
        theme={{
          show: show_BOOK_One_By_One(),
        }}
      >
        <FooterCol2 xs={9}>
          <LabelCertificate className="!tw-text-inspira-brandcolor-txt">{`${getLangLabel(
            "PLEASE BOOK ONE ROOM AT A TIME"
          )}`}</LabelCertificate>
        </FooterCol2>
        <CloseCol2 xs={3} theme={{ show: !isMobile }}>
          <SpanClose
            onClick={() => setShow(false)}
            className="guest-config-span-close--end"
          >
            {" "}
            {getLangLabel("CLOSE")}{" "}
          </SpanClose>
        </CloseCol2>
      </FooterRow>
    </MainContainer>
  );
};
