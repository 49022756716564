import { AddRoomBtn, CustomAddPlusIcon, CustomLabel } from "./styles";
import { useGlobalContext } from "@hooks/useGlobalContext";
import { useWhiteLabelLan } from "@hooks/useLayout";

type GuestConfigurationBodyPropsType = {
  isMobile: boolean;
  setPersonTotal: (config: any, type: string) => void;
};
export const AddRoomButton = ({
  isMobile,
  setPersonTotal,
}: GuestConfigurationBodyPropsType) => {
  const { search, setSearch } = useGlobalContext();
  const newGuestOption = { room: 1, adult: 2, children: 0, child: [] };
  const { getLangLabel } = useWhiteLabelLan();

  /* CERTIFICATE PREVIEW */
  return (
    <AddRoomBtn
      onClick={() => {
        const tempGuestRoom = newGuestOption;
        tempGuestRoom.room = search.paxConfig.length + 1;
        console.log("tempGuestRoom", tempGuestRoom.room, tempGuestRoom);
        const tempPax = [...search.paxConfig, tempGuestRoom];
        if (tempGuestRoom.room <= 8) {
          setSearch({ ...search, paxConfig: tempPax });
          setPersonTotal(tempPax, "all");
        }
      }}
    >
      <CustomAddPlusIcon />
      <CustomLabel
        className="!tw-text-inspira-brandcolor-txt guest-config-custom-label addmorelabel-search"
        theme={{ color: "#6b71a6", isMobile: isMobile }}
      >
        {getLangLabel("ADD ROOM")}
      </CustomLabel>
    </AddRoomBtn>
  );
};
