import {
  BackgroundColorDetectBySite,
  BorderColorDetectBySite,
  BackgroundInputUserIcon,
  ContentImageVoivioUserIcon,
  BackgroundInputLocationDetectBySite,
} from "@styles/general";
import { Button, Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

export const ColInput = styled(Col)`
  /* margin: 0 5px; */
  /* height: 48px; */
  padding: 0 0.3rem;
  cursor: pointer;
  ${({ theme }) => {
    var CSSFinal = ``;
    if (theme.hide == true) {
      CSSFinal += `
        display: none;
      `;
    }
    return CSSFinal;
  }};
  @media only screen and (max-width: 992px) {
    padding: 0;
  }

  /* 
    ${({ theme }) => {
    var CSSFinal = ``;
    if (theme.section == "guestCofiguration") {
      CSSFinal += `height: 48px;
      `;
    }
    return CSSFinal;
  }};
  } */
`;
export const ColInputCustom = styled(Col)`
  padding: 0 0.3rem;
  cursor: pointer;
  ${({ theme }) => {
    var CSSFinal = ``;
    if (theme.hide == true) {
      CSSFinal += `
        display: none;
      `;
    }
    return CSSFinal;
  }};
  @media only screen and (max-width: 992px) {
    padding: 0;
  }

  @media only screen and (min-width: 1020px) {
    max-width: ${({ theme }: any) => theme.width};
  }
`;
export const ColInputDestination = styled(ColInput)`
  @media only screen and (min-width: 1020px) {
    max-width: ${({ theme }: any) => theme.width};
  }
  @media only screen and (max-width: 992px) {
    margin-top: ${(props) => (props.theme.destination ? "-9rem" : "")};
    ${({ theme }) => {
      var CSSFinal = ``;
      if (theme.destination && theme.suggestedListcss) {
        CSSFinal += `margin-left: -2rem;
        margin-top: -9.2rem;
        
      `;
      } else if (theme.destination && !theme.suggestedListcss) {
        CSSFinal += `margin-left: -0.9rem;
      `;
      }
      return CSSFinal;
    }};
  }
`;
export const RowSearch = styled(Row)`
  height: auto;
  border-radius: 10px;
  position: relative;
  margin: 0;
`;

export const ColSearch = styled(Col)`
  border-radius: 10px;
  max-width: ${({ theme }: any) =>
    theme.dropDownAgentsShow && theme.dropDownRecomendedHotels
      ? "1200px"
      : theme.width};
  min-height: 5rem;

  z-index: 2;
  background-color: ${(props) =>
    props.theme.isFloat ? "#ffffff" : "transparent"};
  top: ${(props) => (props.theme.isFloat ? "-3rem" : "1rem")};

  ${(props) => {
    var CSSFinal = ``;
    if (props.theme.isFloat) {
      CSSFinal += `
       -webkit-box-shadow: 0px 0px 5px 2px rgba(69, 77, 97, 0.45);
       box-shadow: 0px 0px 5px 2px rgba(69, 77, 97, 0.45)
       `;
    }

    return CSSFinal;
  }};

  @media only screen and (max-width: 992px) {
    margin-top: 0.5rem;
    background-color: transparent;
    box-shadow: none;
  }

  @media only screen and (min-width: 992px) {
    ${(props) => {
      var CSSFinal = ``;
      if (props.theme.isFloat) {
        CSSFinal += `
           position:absolute;
       `;
      }

      return CSSFinal;
    }};
  }
`;

export const MainDivDestinationInput = styled.div`
  display: inline-flex;
  width: 100%;
  margin: 0;
  height: ${({ theme }: any) => (theme.isMobile == true ? "48px" : "")};
  align-items: ${({ theme }: any) => (theme.isMobile == true ? "center" : "")};
`;

export const DestinationInput = styled.input`
  background-color: #f5f5f5;

  //background-image: url("/images/autocomplete-icon/iconSearchLabel.svg");
  background-repeat: no-repeat;
  background-position: left 1rem center;
  background-size: 0.8rem;

  padding-left: 3rem; //2.2rem;
  border: 1px solid #ddd;
  height: 3rem;
  //color: #4d4d4d;
  font-weight: normal;
  outline: 0;
  font-size: 13px;
  text-transform: capitalize;
  color: #58595b;
  @media only screen and (max-width: 992px) and (max-width: 1350px) {
    background-color: #fff;
  }
  /* @media only screen and (min-width: 1025px) and (max-width: 1350px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 1025px) and (max-width: 1250px) {
    font-size: 11px;
  } */
  @media only screen and (max-width: 992px) {
    font-size: 17px;
    font-weight: ${(props) => (props.theme.destination ? "700" : "")};
    border: ${(props) => (props.theme.destination ? "0" : "")};
    /*border-bottom: ${(props) =>
      props.theme.destination ? "1px solid #ddd" : ""};*/
    // padding-left: ${(props) => (props.theme.destination ? "2.5rem" : "")};
    ${({ theme }) => {
      var CSSFinal = ``;
      if (theme.destination && theme.suggestedListcss) {
        CSSFinal += `padding-left: 3.5rem;
        
      `;
      } else if (theme.destination && !theme.suggestedListcss) {
        CSSFinal += `padding-left: 2.5rem;
      `;
      }
      return CSSFinal;
    }};
  }

  ${BackgroundInputLocationDetectBySite}
`;

export const DivDestinationInputIcon = styled.div`
  background-image: url("/images/autocomplete-icon/iconSearchLabel.svg");
  background-repeat: no-repeat;
  //background-position: center;
  background-size: 0.7rem;
  background-position-x: 0.6rem;
  background-position-y: ${({ theme }: any) =>
    theme.isMobile == true ? `0.5rem` : `1rem`};
  width: 1.5rem;
  height: 2.1rem;
  margin-right: 0.3rem;
  background-color: ${({ theme }: any) =>
    theme.isMobile == true ? `#FFFFFF` : `#f1f1f1`};
`;

export const DateSpan = styled.span`
  //background-image: url("/images/calendar/calsearch.png");
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 19px 19px;
  //padding-left: 1.7rem;
  font-weight: normal;
  font-size: 13px;
  color: #58595b;
  width: 100%;
  @media only screen and (min-width: 1025px) and (max-width: 1510px) {
    font-size: 13px;
    //margin-left: -0.3rem;
    /* background-position-y: 0.5rem; */
    //padding-left: 1.3rem;
  }
  @media only screen and (max-width: 992px) and (max-width: 1350px) {
    font-size: 13px;
    padding-left: 1.7rem;
  }

  /* @media only screen and (min-width: 1025px) and (max-width: 1400px) {
    font-size: 11px;
    background-size: 17px 17px;
  }
  @media only screen and (min-width: 1025px) and (max-width: 1280px) {
    font-size: 11px;
    //padding-left: 1.3rem;
  }
  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    font-size: 11px;
    //padding-left: 1.3rem;
  }
  @media only screen and (min-width: 1025px) and (max-width: 1100px) {
    font-size: 9px;
    //padding-left: 1.8em;
    background-size: 13px 13px;
  } */
  /* @media only screen and (max-width: 1024px) {
    background-position-x: 0rem;
    font-size: 0.72rem;
  } */
`;
export const DateSpan2 = styled.span`
  //background-image: url("/images/calendar/calsearch.png");
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 19px 19px;
  //padding-left: 1.7rem;
  font-weight: normal;
  font-size: 13px;
  color: #58595b;
  width: 100%;
  @media only screen and (max-width: 992px) {
    font-size: 15px;
    padding-left: 1.7rem;
  }
  /* @media only screen and (min-width: 1025px) and (max-width: 1510px) {
    font-size: 13px;
    //margin-left: -0.3rem;
    //padding-left: 1.3rem;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1400px) {
    font-size: 12px;
    background-size: 17px 17px;
    //margin-left: 0rem;
  }
  @media only screen and (min-width: 1025px) and (max-width: 1280px) {
    font-size: 10px;
    //padding-left: 1.5rem;
  }
  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    font-size: 11px;
    //padding-left: 1.5rem;
  }
  @media only screen and (min-width: 1025px) and (max-width: 1100px) {
    font-size: 9px;
    //padding-left: 1.8em;
    background-size: 13px 13px;
  } */
  /* 
  @media only screen and (max-width: 1024px) {
    font-size: 0.72rem;
    background-position-x: 0.2rem;
  } */
`;
export const Separator = styled.span`
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  width: 2px;
  height: 1.5rem;
  position: absolute;
  right: 0;
  //padding-right: 15px;
`;
export const ColDate1 = styled(Col)`
  position: relative;
  padding: 0.7rem 0;
  /* margin: 0; */
  /* background-color: #f5f5f5; */
  height: 3rem;

  background-color: #f5f5f5;
  border-radius: 5px 0px 0px 5px;
  border-top: 1px solid #ddd;
  border-right: none;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;

  @media only screen and (max-width: 992px) {
    background-color: #fff;
  }
`;
export const ColDate2 = styled(Col)`
  padding: 0.7rem 0;
  /* background-color: #f5f5f5; */
  height: 3rem;
  background-color: #f5f5f5;
  border-radius: 0px 5px 5px 0px;
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-left: none;
  position: relative;
  @media only screen and (max-width: 992px) {
    background-color: #fff;
  }
`;
export const ColSeparator = styled(Col)`
  //margin-top: 0rem;
  //padding: 0;
`;
export const MainRowDate = styled(Row)`
  margin: 0;

  @media only screen and (max-width: 992px) {
    margin-top: 0.7rem;
  }
`;

export const SearchSpan = styled.span`
  /* width: 2rem;
  height: 2rem; */
  display: inline-flex;
  /* margin-left: -0.5rem; */
  //vertical-align: middle;

  ${({ theme }: any) => {
    var CSSFinal = "";
    if (theme.isMobile) {
      CSSFinal += ` 
                    background-size: 19px;
                    background-position-y: center;
                    margin-left: 15.5rem;
      `;
    }
    return CSSFinal;
  }};
`;
export const SearchBtn = styled(Button)`
  background-color: #686da4;
  transition-duration: 0.4s;
  :hover {
    background-color: #1d6ca7;
  }

  background-image: url("/images/search/search_new.svg");
  background-repeat: no-repeat;
  background-position: center right 1rem;
  background-size: 20px;
  ${({ theme }: any) => {
    var CSSFinal = "";
    if (theme.isMobile) {
      //margin-top: 1rem;
      if (theme.ishoteldetails) {
        CSSFinal += `margin-top: -0.7rem;
      `;
      } else {
        CSSFinal += `margin-top: 0.5rem;
      `;
      }
      CSSFinal += `width: 100%;
                   border-radius: 20px;
                   background-color: #123E78;
      `;
    } else {
      CSSFinal += `width: 3.2rem;
                  height: 3rem;
      `;
    }
    return CSSFinal;
  }};
  ${BackgroundColorDetectBySite}
  ${BorderColorDetectBySite}
  @media only screen and (max-width: 992px) {
    background-position: center right 1.5rem;
  }
`;

export const SearchBtnTittleMobile = styled.span`
  margin-top: 0.2rem;
  margin-left: -9.4rem;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1px;
  text-align: center;
  color: #fff;
  @media only screen and (max-width: 992px) {
    font-size: 22px;
    font-weight: 600;
    margin-left: -10.4rem;
  }
`;
export const ColSearchInputMobile = styled(Col)`
  width: 100%;
  margin-top: 2rem !important;

  @media only screen and (min-width: 992px) {
    display: none;
  }

  ${({ theme }) => {
    var CSSFinal = ``;

    if (theme.isMobile == true) {
      CSSFinal += `
                   background-color: #ffffff;
                   box-shadow: 0 0 10px 0 #ccc;
      `;
    }

    return CSSFinal;
  }};
`;

export const MainContainer = styled(Container)`
  @media only screen and (min-width: 993px) and (max-width: 1023px) {
    position: absolute;
    margin-top: 55%;
  }
  transition: width 2s;
  position: relative;
  text-align: center;
  background-color: transparent;
  padding: 0 1rem;

  ${(props) => {
    var CSSFinal = "";
    if (props.theme.isFloat) {
      CSSFinal += `
      `;
    } else {
      CSSFinal += `
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      `;
    }

    return CSSFinal;
  }};

  @media only screen and (max-width: 992px) {
    background-color: transparent;
    height: auto;
    /* margin-bottom: 4.5rem; */
    /* max-height: 22rem; */
  }
  @media only screen and (min-width: 1200px) {
    padding: 0;
  }
`;

export const RowMain = styled(Row)`
  padding: 1.5rem 1rem;
  padding-left: ${({ theme }: any) =>
    theme.IS_B2B && theme.userType == 1 ? "0.5rem" : "3.5rem"};

  @media only screen and (max-width: 992px) {
    padding: 0;
    ${(props) => (props.theme.isFloat ? "" : "padding: .5rem 1rem")};
  }
  @media only screen and (max-width: 1300px) {
    ${(props) => (props.theme.isFloat ? "" : "padding: .5rem 1rem")};
  }
  @media only screen and (min-width: 992px) {
    justify-content: center;
  }

  /* @media only screen and (min-width: 1024px) {
    justify-content: ${(props) =>
    props.theme.current_language == "de-DE" ? "flex-start" : "center"};
  } */

  @media only screen and (min-width: 1300px) {
    ${(props) => (props.theme.isFloat ? "" : "padding: 1rem 0rem")};
  }
`;

export const SpanIconPanxConfig = styled.img`
  position: absolute;
  width: 20px;
  height: auto;
  content: url("/images/PanxConfigMobileIcons/panxConfigIcon.svg");
  left: 1rem;
  top: 1rem;

  @media only screen and (min-width: 992px) {
    display: none;
  }
  ${ContentImageVoivioUserIcon}
`;

export const DivTittlePanxConfigMobile = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.3px;
  color: #4d4d4f;
  width: auto;
  margin-top: -1.8rem;
  text-transform: capitalize;
`;

export const RowMainTittleMobile = styled(Row)`
  padding: 0;
  margin: 0;
  margin-top: 2.5rem;
  margin-bottom: 1rem;

  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

export const MainDivTittleMobile = styled.div`
  font-size: 19px !important;
  font-weight: 700;
  color: #123e78;
  line-height: 1.2rem;
  text-align: center;
  padding: 0;
  margin: 0;
  @media only screen and (min-width: 992px) {
    font-size: 31px;
    line-height: 2rem;
  }
  @media only screen and (max-width: 992px) {
    text-align: left;
    font-size: 31px;
  }
`;

export const SpanPanConfigTittle = styled.div`
  //background-image: url("/images/PanxConfigMobileIcons/panxConfigIcon.svg");
  background-repeat: no-repeat;
  background-size: 1.2rem;
  background-position: left 1rem center;
  position: relative;
  background-color: #f5f5f5;
  height: 3rem;
  padding: 0.6rem 0;
  color: #58595b;
  font-size: 13px;
  font-weight: normal;
  line-height: 2rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  line-height: 1.5rem;
  @media only screen and (max-width: 992px) {
    background-color: #fff;
    margin-top: 1rem;
    font-size: 15px;
    /* padding: 1.5rem 0; */
  }

  @media only screen and (min-width: 992px) {
    /* width: 18rem; */
  }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    /* padding: 1.4rem 0; */
  }
  /* @media only screen and (min-width: 1025px) and (max-width: 1350px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 1025px) and (max-width: 1250px) {
    font-size: 11px;
  }
  @media only screen and (min-width: 1025px) and (max-width: 1100px) {
    font-size: 10px;
  } */
  /* @media only screen and (min-width: 992px) {
    font-size: 12px;
  }
  @media only screen and (max-width: 1025px) {
    line-height: 0.2rem;
  } */

  ${BackgroundInputUserIcon}
  @media only screen and (min-width: 1024px) {
    /* overflow: ${(props) =>
      props.theme.current_language === "de-DE" ? "hidden" : "visible"};
    white-space: ${(props) =>
      props.theme.current_language === "de-DE" ? "nowrap" : "normal"};
    text-overflow: ${(props) =>
      props.theme.current_language === "de-DE" ? "ellipsis" : "clip"};
    padding-left: ${(props) =>
      props.theme.current_language === "de-DE" ? "17%" : "0"};
    padding-right: ${(props) =>
      props.theme.current_language === "de-DE" ? "15%" : "0"};
    padding-top: ${(props) =>
      props.theme.current_language === "de-DE" ? "7%" : "4%"};
    padding-bottom: ${(props) =>
      props.theme.current_language === "de-DE" ? "0%" : "0"}; */

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: 18%;
    padding-right: 15%;
    padding-top: 5%;
    padding-bottom: 0%;
  }

  @media only screen and (min-width: 1200px) {
    padding-top: 4%;
  }
`;

export const ColBack = styled(Col)`
  /* 
  enter; */
  background-size: 1.5rem;
  /* padding: -0.3rem; */
  /* padding-top: 0.5rem; */
  /* padding-left: 4rem; */
  line-height: 3rem;
  color: rgb(87, 87, 87);
  font-weight: 600;
  font-size: 1.1rem;
  cursor: pointer;
  text-align: center;

  @media only screen and (min-width: 992px) {
    max-width: 20%;
    padding: 0rem;
    background-size: 2rem;
  }

  @media only screen and (max-width: 1024px) {
    max-width: 24%;
    padding: 0rem;
    background-size: 2rem;
  }

  @media only screen and (max-width: 992px) {
    font-size: 0.9rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  @media only screen and (min-width: 1020px) {
    max-width: 15.5%;
    text-align: center;
    margin-right: -1rem;
  }
  @media only screen and (max-width: 1380px) {
    display: none;
  }
`;

export const ColBtnSearch = styled(Col)`
  text-align: left;
  align-items: left;
  margin: 0;
  padding: 1.5rem 0rem;
  margin-left: -0.5rem;
  ${(props) => (props.theme.isFloat ? "" : "padding: .5rem 0rem")};
  /* @media only screen and (min-width: 992px) and (max-width: 1250px) {
    padding: 0 0.5rem;
  } */

  @media only screen and (max-width: 1020px) {
    display: none;
  }
  @media only screen and (max-width: 1024px) {
    ${(props) => (props.theme.isFloat ? "" : "padding: .5rem 0rem")};
  }
  @media only screen and (max-width: 1300px) {
    ${(props) => (props.theme.isFloat ? "" : "padding: .5rem 0rem")};
  }
  @media only screen and (min-width: 1300px) {
    ${(props) => (props.theme.isFloat ? "" : "padding: 1rem 0rem")};
  }
`;
export const ImgPointerDown = styled.img`
  position: absolute;
  content: url(/images/filter/pexarrowdown.svg);
  width: 0.5rem;
  opacity: 0.5;
  right: 1rem;
  top: 1.45rem;
  @media only screen and (max-width: 992px) {
    top: 1.25rem;
  }
`;

export const DivFlexB2BSearchOptions = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const DivLeftCliente = styled.div`
  width: 56%;
`;
export const DivRightCliente = styled.div`
  width: 40%;
`;

export const SearchClientContainer = styled.div`
  display: flex;
  align-items: center;
  background-position: 10px 17px;
  padding-left: 10px;
  background-color: ${({ theme }: any) =>
    theme.isMobile ? "#fff" : "#f5f5f5"};
  background-size: 16px;
  width: 100%;
  height: 47px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: ${({ theme }: any) => (theme.isMobile ? "1rem" : "0rem")};
`;
export const SearchClientNationalityContainer = styled.div`
  display: flex;
  align-items: center;
  background-position: 10px 17px;
  padding-left: 10px;
  background-color: ${({ theme }: any) =>
    theme.isMobile ? "#fff" : "#f5f5f5"};
  //background-size: 16px;
  width: 100%;
  height: 47px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: ${({ theme }: any) => (theme.isMobile ? "1rem" : "0rem")};
`;

export const InputSearchClientContainer = styled.div`
  display: flex;
`;

export const DivImgSearch = styled.div`
  margin-right: 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const ImgSearch = styled.img`
  content: url(/images//search/searchIcon.svg);
  width: 1rem;
  height: 1rem;
`;
export const DivInputSearchAgent = styled.div`
  position: relative;
  width: 100%;
`;
export const InputSearchAgent = styled.input`
  background-color: ${({ theme }: any) =>
    theme.isMobile ? "#fff" : "#f5f5f5"};
  width: 100%;
  height: 40px;
  border: none;
  font-size: 12px;

  :focus {
    border: none;
    outline: none;
  }

  ::placeholder {
    color: #868686;
    font-size: 10px;
    text-align: left;
    font-weight: 500;
  }
`;

export const DivContainerAgents = styled.div`
  position: absolute;
  z-index: 2;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 10px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px 0 #aaa;
  margin-top: 0.5rem;
  margin-left: -2rem;
  min-width: 10rem;
  overflow-y: auto;
  max-height: 10rem;
  min-width: 15rem;
`;
export const AgentOption = styled(Row)`
  cursor: pointer;
  background-color: ${(props) => (props.theme.isSelected ? "#f1f2f7" : "#fff")};
  border-radius: ${(props) => (props.theme.isSelected ? "15px;" : "0")};

  margin: 0.5rem 0.5rem;
  &:hover {
    background-color: #f1f2f7;
    border-radius: 15px;
  }
`;

export const AgentDivFlex = styled.div`
  display: flex;
`;

export const DivAgentImgSelected = styled.div`
  margin-right: 0.25rem;
`;
export const AgentImgSelected = styled.img`
  content: ${({ theme }: any) =>
    theme.isSelected
      ? "url(/images/search/iconAgentSelected.png)"
      : "url(/images/search/iconAgentNoSelectec.png)"};
  width: 1rem;
  height: 1rem;
`;

export const DivAgentName = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const SpanAgentName = styled.span`
  font-size: 13px;
  color: #8c8c8c;
`;

export const DivDockBlue = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 0.4rem;
`;
export const DickBlue = styled.img`
  content: url(/images/search/iconDockBlue.svg);
  width: 0.6rem;
  height: 0.6rem;
  opacity: 0.5 !important;
  ${({ theme }: any) =>
    () => {
      var CSSFinal = "";
      const isSelected = theme.isSelected;

      if (!isSelected) {
        CSSFinal += `transform: rotate(180deg);
                     translateX(0.25em);
                     transition: 0.4s ease;`;
      } else {
        CSSFinal += `transform: rotate(0deg);
                     translateX(0.25em);
                     transition: 0.4s ease;`;
      }
      return CSSFinal;
    }};
`;
export const NationalityDivFlex = styled.div`
  display: flex;
`;
export const DivImgNationality = styled.div`
  margin-right: 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const ImgNationality = styled.img`
  content: url(/images//search/nationalityIcon.svg);
  width: 1rem;
  height: 1rem;
`;
export const DivInputSearchNationality = styled.div`
  position: relative;
  width: 100%;
`;
export const InputSearchNationality = styled.input`
  background-color: ${({ theme }: any) =>
    theme.isMobile ? "#fff" : "#f5f5f5"};
  width: 100%;
  height: 40px;
  border: none;
  font-size: 12px;

  :focus {
    border: none;
    outline: none;
  }

  ::placeholder {
    color: #868686;
    font-size: 10px;
    text-align: left;
    font-weight: 500;
  }
`;
export const DivContainerNationality = styled.div`
  position: absolute;
  z-index: 2;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 10px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px 0 #aaa;
  margin-top: 0.5rem;
  margin-left: ${({ theme }: any) => (theme.isMobile ? "-8.5rem" : "-2rem")};
  min-width: 10rem;
  overflow-y: auto;
  max-height: 10rem;
  min-width: 15rem;
`;
export const NationalityOption = styled(Row)`
  cursor: pointer;
  background-color: ${(props) => (props.theme.isSelected ? "#f1f2f7" : "#fff")};
  border-radius: ${(props) => (props.theme.isSelected ? "15px;" : "0")};

  margin: 0.5rem 0.5rem;
  padding: 5px 10px;
  &:hover {
    background-color: #f1f2f7;
    border-radius: 15px;
  }
`;

export const LabelNotFound = styled.div`
  font-size: 17px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #686ea3;
  //margin: 15px 0;
`;

export const DivImgResultNotFound = styled.div``;
export const ImgResultNotFound = styled.img`
  content: url(/images/search/imgResultNotFoundSmall.jfif);
  width: 100%;
  height: 7rem;
`;

export const DivANationalityName = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const SpanNationalityName = styled.span`
  font-size: 13px;
  color: #8c8c8c;
  text-align: left;
`;

export const DivCheckBoxPackage = styled.div``;

export const CheckBoxPackage = styled.input`
  width: ${({ theme }: any) => (theme.isMobile ? "1.4rem" : "1.7rem")};
  height: ${({ theme }: any) => (theme.isMobile ? "1.4rem" : "1.7rem")};
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-top-color: rgba(0, 0, 0, 0.25);
  border-right-color: rgba(0, 0, 0, 0.25);
  border-bottom-color: rgba(0, 0, 0, 0.25);
  border-left-color: rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;

  :checked {
    background-color: #b4b8d3;
    border-color: #123e78;
  }
  border-radius: 0.25em;
  box-sizing: border-box;
  :checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }
  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }

  :focus {
    border-color: #123e78;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(18, 102, 241, 0.25);
  }
`;

export const DivLabelPackage = styled.div`
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SpanLabelPackage = styled.span`
  font-size: 15px;
  color: #4d4d4f;
  font-weight: 600;
`;

export const LabelErrorSearch = styled.div`
  color: red;
  font-size: 14px;
  text-align: right;
`;
export const WalletPersonalContainer = styled.div`
  display: ${({ theme }: any) => (theme.show ? "flex" : "none")};
  justify-content: right;
  //margin-top: -3rem;
`;
export const Img = styled.img`
  padding: 0px;
  position: absolute;
  width: 0.9rem;
  margin: 0.8rem 1rem;
  ${({ theme }) => {
    var CSSFinal = ``;
    if (theme.destination && theme.suggestedListcss) {
      CSSFinal += `margin: 0.9rem 2rem;
      `;
    }
    return CSSFinal;
  }};
`;

export const ImgBack = styled.img`
  padding: 0px;
  position: absolute;
  width: 1.5rem;
  display: block;
  margin: 1rem 0rem;
`;

export const ImgCalendar = styled.img`
  padding: 0px;
  position: absolute;
  left: 2.5rem;
  width: 1.2rem;
  margin: 0.2rem -1.5rem;
  /* @media only screen and (max-width: 992px) {
    margin: 0.2rem -2rem;
    margin: 0.2rem -1.1rem;
  } */
`;
export const ImgUser = styled.img`
  padding: 0px;
  position: absolute;
  width: 1.2rem;
  left: 1rem;
  top: 1rem;
  @media only screen and (min-width: 992px) {
    width: 1.2rem;
    margin: 0rem 0rem;
  }
  @media only screen and (min-width: 867px) and (max-width: 1200px) {
    left: 0.5rem;
  }
`;
export const DivBackShowOverlay = styled.div`
  background-color: #f5f5f5;

  //background-image: url("/images/header/Close.svg");
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: left 1rem center;
  background-size: 0.8rem;

  padding-left: 2.2rem;
  border: 1px solid #ddd;
  height: 3.2rem;
  //color: #4d4d4d;
  font-weight: 400;
  outline: 0;
  font-size: 15px;
  text-transform: capitalize;
  background-color: #fff;
  @media only screen and (min-width: 992px) {
    display: none;
  }

  @media only screen and (max-width: 992px) {
    display: ${(props) => (props.theme.destination ? "" : "none")};
    border: 0;
    height: 2.8rem;

    border-bottom: ${(props) =>
      props.theme.destination ? "1px solid #ddd" : ""};
    ${({ theme }) => {
      var CSSFinal = ``;
      if (theme.destination && theme.suggestedListcss) {
        CSSFinal += `margin-right: -2.5rem;
        margin-top: 0rem;
        
      `;
      } else if (theme.destination && !theme.suggestedListcss) {
        CSSFinal += `margin-right: -1.9rem;
        margin-top: 0.2rem;
      `;
      }
      return CSSFinal;
    }};
  }
  padding-left: 3.5rem;
`;
export const CloseIcon = styled.img`
  width: 1rem;
  height: 1rem;
  margin-top: 1rem;
  margin-left: -3.5rem;
`;
